// src/components/Loading.js
import React from 'react';
import './Loading.css';  // Import the styles for the animation

const Loading = () => {
    return (
        <div className="loading-container">
            <div className="spinner"></div>
            <p className="loading-text">Loading...</p>
        </div>
    );
};

export default Loading;
