import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { loginUser } from '../services/api';
import { jwtDecode } from 'jwt-decode';


const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    // Function to check if the token is valid and not expired
    const isTokenValid = (token) => {
        try {
            const decoded = jwtDecode(token);
            const currentTime = Date.now() / 1000; // Current time in seconds
            return decoded.exp > currentTime;
        } catch (error) {
            console.error('Invalid token', error);
            return false;
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token && isTokenValid(token)) {
            // Decode the token to get user info
            const decoded = jwtDecode(token);
            console.log("Decoded User (useEffect):", decoded);

            // Redirect based on role
            if (decoded.role === 'admin') {
                navigate('/admin');
            } else if (decoded.role === 'estimator') {
                navigate('/estimator');  // Redirect estimator to their dashboard
            } else {
                navigate('/dashboard');
            }
        }
    }, [navigate]); // Only run this once after the component mounts

    const handleSubmit = async (e) => {
        console.log(email)
        e.preventDefault();

        try {
            const data = await loginUser(email, password);
            if (!data.token) {
                throw new Error('Login failed: No token returned');
            }

            localStorage.setItem('token', data.token);

            // Decode the token to get user info
            const decoded = jwtDecode(data.token);
            console.log("Decoded User (handleSubmit):", decoded.user.role);

            // Redirect based on role
            if (decoded.user.role === 'admin') {
                navigate('/admin'); // Redirect admin to admin page
            } else if (decoded.user.role === 'estimator') {
                navigate('/estimator');  // Redirect estimator to their dashboard
            } else {
                navigate('/dashboard'); // Redirect non-admins to dashboard
            }
        } catch (err) {
            // Capture specific API errors or display generic error
            const errorMsg = err.response?.data?.error || 'Invalid email or password';
            setError(errorMsg);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                <h2 className="text-2xl font-bold mb-4 text-center">Login</h2>
                {error && <p className="text-red-500">{error}</p>}
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700">Email</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300"
                            placeholder="Enter your email"
                            required
                        />
                    </div>
                    <div className="mb-6">
                        <label className="block text-gray-700">Password</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300"
                            placeholder="Enter your password"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition duration-300"
                    >
                        Login
                    </button>
                </form>
                <p className="mt-4 text-center">
                    Don't have an account? <Link to="/register" className="text-blue-500">Register here</Link>
                </p>
            </div>
        </div>
    );
};

export default Login;
