// utils/tokenHelper.js
import { jwtDecode } from 'jwt-decode';

export const isTokenValid = (token) => {
    if (!token) return null;

    try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;  // Current time in seconds

        // Check if the token has expired
        if (decoded.exp < currentTime) {
            localStorage.removeItem('token');  // Clear the expired token
            return null;
        }

        return decoded;  // Return the decoded token if it's valid
    } catch (error) {
        console.error('Invalid token:', error);
        return null;
    }
};