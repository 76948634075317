// components/ProtectedRoute.js
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { isTokenValid } from '../utils/tokenHelper';
import { fetchPropertiesByUserId } from '../services/api';

const ProtectedRoute = ({ children, role }) => {
    const token = localStorage.getItem('token');
    const user = isTokenValid(token); // Decode and validate the token

    const userId = user?.id;
    const userRole = user?.role;

    const [propertyCount, setPropertyCount] = useState(null);

    useEffect(() => {
        if (userRole === 'client') {
            fetchPropertiesByUserId(userId)
                .then((properties) => {
                    setPropertyCount(properties.length || 0);
                })
                .catch((error) => {
                    console.error("Error fetching properties:", error);
                    setPropertyCount(0);
                });
        }
    }, [userId, userRole]);

    // Redirect to login if the token is invalid
    if (!user) {
        return <Navigate to="/login" replace />;
    }

    // Redirect clients with no properties to the landing page
    if (userRole === 'client' && propertyCount === 0) {
        return <Navigate to="/welcome" replace />;
    }

    // Redirect based on user role if role doesn't match
    if (role && userRole !== role) {
        if (userRole === 'estimator') return <Navigate to="/estimator" replace />;
        if (userRole === 'admin') return <Navigate to="/admin" replace />;
        if (userRole === 'client') return <Navigate to="/dashboard" replace />;
    }

    // Render the protected content if authenticated and authorized
    return children;
};

export default ProtectedRoute;
